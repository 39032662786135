import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Facebook from '../common/Facebook';
import Instagram from '../common/Instagram';
import Linkedin from '../common/Linkedin';
import { AddressDetails, SocialLink } from '../Footer';

const Footer = ({ footerImg, footerEitImg }) => (
	<footer className="bg-white">
		<div className="container">
			<div className="row pt-5">
				<div className="col-6 col-md-3">
					<p className="font-weight-bolder mb-4">Rólunk</p>
					<p className="small mb-3">
						<a href="/policy.pdf" className="text-secondary text-underline">
							Adatvédelmi nyilatkozat
						</a>
					</p>
					<div className="mb-3">
						<SocialLink
							href="https://www.facebook.com/viddlhungary"
							label="Facebook"
						>
							<Facebook />
						</SocialLink>
						<SocialLink
							href="https://www.linkedin.com/company/viddl"
							label="Linkedin"
						>
							<Linkedin />
						</SocialLink>
						<SocialLink
							href="https://www.instagram.com/viddl_"
							label="Instagram"
							isLast
						>
							<Instagram />
						</SocialLink>
					</div>
				</div>
				<div className="col-6 col-md-3">
					<AddressDetails title="Elérhetőségeink" email="info@viddl.hu" />
				</div>
				<div className="col-6 col-md-6 text-right">
					<Img fixed={footerEitImg.childImageSharp.fixed} />
				</div>
				<div className="col-12 text-right">
					<Link to="/" aria-label="ViddL">
						<Img fixed={footerImg.childImageSharp.fixed} />
					</Link>
				</div>
				<div className="col-12 pb-3">
					<p className="small">© {new Date().getFullYear()} Cargo-Viszed Kft</p>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
