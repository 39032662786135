import React from 'react';
import PropTypes from 'prop-types';

const linkTextClassName = 'small mb-3';
const anchorTextClassName = 'text-secondary text-underline';
const secondaryTextClassName = `${linkTextClassName} text-secondary`;

export default function AddressDetails(props) {
	const { title, address, href, mobile, email, support } = props;
	return (
		<div className="col-12 mb-3">
			<p className="font-weight-bolder mb-4">{title}</p>
			{address && href && (
				<p className={linkTextClassName}>
					<a
						className={anchorTextClassName}
						href={href}
						target="_blank"
						rel="noopener noreferrer"
					>
						{address}
					</a>
				</p>
			)}
			{mobile && <p className={secondaryTextClassName}>{mobile}</p>}
			{email && (
				<p className={linkTextClassName}>
					<a className={anchorTextClassName} href={`mailto:${email}`}>
						{email}
					</a>
				</p>
			)}
			{support && (
				<p className={secondaryTextClassName}>Ügyfélszolgálat: {support}</p>
			)}
		</div>
	);
}

AddressDetails.propTypes = {
	title: PropTypes.string.isRequired,
	address: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	mobile: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};
