import React from 'react';

import Header from './Header';
import SEO from './SEO';
import Footer from './Footer';

const Layout = ({
	description,
	keywords,
	title,
	children,
	footerImg,
	footerEitImg,
}) => (
	<>
		<SEO description={description} keywords={keywords} title={title} />
		<Header />
		{children}
		<Footer footerImg={footerImg} footerEitImg={footerEitImg} />
	</>
);

export default Layout;
